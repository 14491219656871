import React, { Suspense } from 'react';

// ROUTER

import AppRouter from './AppRouter.js';

// CONTEXT

import { AuthProvider } from './Context/AuthContext.js';

import { LocalizationProvider } from './Context/LocalizationContext.js';

// COMPONENTS

import Loader from './Components/Loader/Loader.js';

import NotificationContainer from './Components/Notification2/NotificationContainer.js';

// STYLING

import './App.css';

import './Components/Buttons/Buttons.css';

import './Components/Icons/Icons.css';

import './Components/Wrapper/Wrapper.css';

//

function App() {

  return (
    <div className="App">
      <LocalizationProvider>
        <AuthProvider>
          <Suspense fallback={<Loader />}>
            <AppRouter/>
          </Suspense>
        </AuthProvider>
      </LocalizationProvider>
      <NotificationContainer />
    </div>
  );

}

export default App;